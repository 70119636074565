.user-comment-edit-form {
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 10px;
    margin-bottom: 20px;
    width: 500px;
}

.user-comment-edit-form > input {
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 15px;
    padding: 0 5px;
}
.user-comment-edit-form > button {
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    background-color: #fff;
}
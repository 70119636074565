.comment-author {
    font-size: 16px;
    font-weight: 600;
    color: #434343;
}
.comment-msg {
    color:#555555;
    font-size: 15px;
}
.comment-date {
    color: #434343;
    font-weight: 600;
    font-size: 14px;
}
.comment-block {
    width: 500px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}
.comment-head {
    display: grid;
    grid-template-columns: min-content auto;
    justify-content: space-between;

}